import React from "react"
import { StoryGroup } from "components"

const LinkMap = {
  Webpack: "fd07377ef195",
  Parcel: "fd07377ef195",
  Rollup: "fd07377ef195",
  Bundler: "fd07377ef195",

  "NPM Link": "45db75de3310",
  LocalPath: "6a064a5717aa",
}

const stories = [
  {
    title: "Webpack’e Başlangıç",
    postId: "fd07377ef195",
  },
  {
    title: "Vite + React + TypeScript ile Counter Uygulaması",
    postId: "45b8f438149a",
  },
  {
    title: "CRA to Vite.js Migration",
    postId: "ddf843861c24",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "React Bundlers",
  path: "react-webpack",
}

const ReactWebpackPage = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default ReactWebpackPage
